import jQuery from 'jquery';

require('waypoints/lib/noframework.waypoints.js');
require('slick-carousel');

(($) => {
  const $mainNavContainer = $('.main-nav-container');
  const $mainNav = $('nav', $mainNavContainer);
  const $arrowPrev = $('.slider-arrow.prev', $mainNavContainer);
  const $arrowNext = $('.slider-arrow.next', $mainNavContainer);
  const slickConfig = {
    dots: false,
    infinite: false,
    // speed: 300,
    slidesToShow: 5,
    // centerMode: true,
    variableWidth: true,
    prevArrow: $arrowPrev,
    nextArrow: $arrowNext,
  };
  const $slickInstance = $mainNav
    .clone()
    .slick(slickConfig);
  $mainNavContainer.append($slickInstance);

  const initNavCarousel = () => {
    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (vw < 1024) {
      $slickInstance.show();
      $arrowPrev.show();
      $arrowNext.show();
      $mainNav.hide();
    } else {
      $slickInstance.hide();
      $arrowPrev.hide();
      $arrowNext.hide();
      $mainNav.show();
    }
  };
  initNavCarousel();

  const $mainNavs = document.querySelectorAll('[data-scroll-to]');
  function removeActiveClass() {
    $mainNavs.forEach((el) => {
      el.classList.remove('active');
    });
  }

  function toggleActiveClass(target) {
    removeActiveClass();
    Array.from($mainNavs)
      .filter((sec) => sec.dataset.scrollTo === target)
      .forEach((elem) => {
        elem.classList.add('active');
      });
  }

  function scrollToTarget() {
    const target = document.querySelector(`[data-scroll-target=${this.dataset.scrollTo}]`);
    window.scrollTo({
      left: 0,
      top: target.offsetTop - 88,
      behavior: 'smooth',
    });
  }
  if ($mainNavs.length > 0) {
    $mainNavs.forEach((el) => {
      const target = el.dataset.scrollTo;
      const wp = new Waypoint({ // eslint-disable-line
        element: document.querySelector(`[data-scroll-target=${target}]`),
        offset: '50%',
        handler() {
          toggleActiveClass(target);
        },
      });
      el.addEventListener('click', scrollToTarget);
    });
  }

  let resizeTimer;
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      initNavCarousel();
    }, 250);
  });
})(jQuery);
