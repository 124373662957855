import jQuery from 'jquery';
import LazyLoad from 'vanilla-lazyload';

(($) => {
  const myLazyLoad = new LazyLoad({
    elements_selector: '.lazy',
  });
  myLazyLoad.update();

  const $employees = $('.employees');
  const $cards = $('.member-card', $employees);
  let $memberProfile = $('.member-profile').detach().removeClass('hidden');
  const $profileContent = $('.content', $memberProfile);
  const $pointer = $('.pointer', $memberProfile);
  const $memberContents = $('.member-profiles-content > div');

  let activeCard = null;
  // let profileOpen = false;
  let perRow = Math.floor($employees.width() / $($cards[0]).width());

  const appendSlider = () => {
    const col = (activeCard % perRow);
    const endRow = (activeCard + perRow - col);
    if ($cards[endRow - 1]) {
      $($cards[endRow - 1]).after($memberProfile);
    } else {
      $employees.append($memberProfile);
    }
    $memberProfile.removeClass('hidden');
  };

  const hasNextPrev = () => {
    if ($cards[activeCard + 1]) {
      $memberProfile.addClass('has-next');
    } else {
      $memberProfile.removeClass('has-next');
    }
    if ($cards[activeCard - 1]) {
      $memberProfile.addClass('has-prev');
    } else {
      $memberProfile.removeClass('has-prev');
    }
  };

  const scrollToRow = () => {
    const $card = $($cards[activeCard]);
    // const top = $card.offset().top + $card.height() - 76;
    const top = $card.offset().top + $card.height() - 126;
    window.scrollTo({
      top,
      left: 0,
      behavior: 'smooth',
    });
  };

  const movePointer = () => {
    const card = $cards[activeCard];
    $pointer.css('left', card.offsetLeft + 10 + (card.clientWidth / 2));
    // $pointer.css('left', card.offsetLeft + (card.clientWidth / 2));
  };

  const handleMemberClick = (elem) => {
    let $card = false;
    if (elem.originalEvent instanceof Event) {
      $card = $(elem.target).closest('.member-card');
    } else {
      $card = $(elem);
    }
    activeCard = $card.data('member');
    $profileContent.html($memberContents[activeCard]);
    appendSlider();
    hasNextPrev();
    movePointer();
    $memberProfile.addClass('visible');
    $('> div', $profileContent).removeAttr('style');
    $profileContent.slideDown(() => {
      setTimeout(() => {
        scrollToRow();
      }, 100);
    });
  };

  const handleNavClick = (next = true) => {
    // eslint-disable-next-line no-unused-expressions
    next ? activeCard += 1 : activeCard -= 1;
    const $content = $($memberContents[activeCard]);
    if (next && activeCard % perRow === 0) {
      appendSlider();
      scrollToRow();
    }
    if (!next && (activeCard + 1) % perRow === 0) {
      appendSlider();
      scrollToRow();
    }

    $content.css({
      display: 'none',
      position: 'absolute',
    })
      .fadeIn(750, function() { // eslint-disable-line
        this.style.removeProperty('position');
      });
    const $toHide = $('> div', $profileContent);
    $toHide.fadeOut(760, function () { // eslint-disable-line
      $(this).remove();
    });
    hasNextPrev();
    movePointer();
    $profileContent.prepend($content);
  };

  const handleCloseClick = () => {
    $memberProfile.removeClass('visible');
    $profileContent.slideUp(() => {
      $profileContent.removeAttr('style');
      $memberProfile = $memberProfile.detach();
    });
  };

  $cards.on('click', '.member', (ev) => handleMemberClick(ev));
  $memberProfile.on('click', '.close', handleCloseClick);
  $memberProfile.on('click', '.arrow-next', () => handleNavClick());
  $memberProfile.on('click', '.arrow-prev', () => handleNavClick(false));

  if (window.location.hash) {
    const slug = window.location.hash.split('#')[1];
    const $calledMember = document.querySelector(`[data-member-slug='${slug}']`);
    if ($calledMember) {
      handleMemberClick($calledMember);
    }
  }

  let resizeTimer;
  $(window).on('resize', () => {
    if (resizeTimer) {
      clearTimeout(resizeTimer); // clear any previous pending timer
    }
    resizeTimer = setTimeout(() => {
      perRow = Math.floor($employees.width() / $($cards[0]).width());
      if ($memberProfile.hasClass('visible')) {
        $memberProfile.addClass('hidden');
        appendSlider();
        movePointer();
      }
    }, 200);
  });
})(jQuery);
